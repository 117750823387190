export default {
  signup: '/api/auth/signup',
  resendRegisterLink: '/api/auth/resend-verify-signup',
  verifySignup: '/api/auth/verify-signup',
  forgotPassword: '/api/auth/forgot-pass',
  resetPassword: '/api/auth/reset-pass',
  account: '/api/auth/account',
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  stores: '/api/stores',
}
