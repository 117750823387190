import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/register-success/:id/:email',
    name: 'RegisterSuccess',
    component: () => import('../views/RegisterSuccessView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordView.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPasswordView.vue')
  },
  {
    path: '/dashboard',
    component: () => import('../layouts/DashboardLayout'),
    children: [
      {
        path: '',
        component: () => import('../views/DashboardView.vue'),
        children: [
          {
            path: 'add-store',
            component: () => import('../components/AddStore.vue')
          }
        ]
      },
      {
        path: 'activity',
        name: 'Activity',
        component: () => import('../views/ActivityView.vue')
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/AccountView.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicRoutes = [
    'Login',
    'Register',
    'RegisterSuccess',
    'ForgotPassword'
  ]
  publicRoutes.includes(to.name)
    ? next()
    : store.state.auth.user.name
    ? next()
    : store.dispatch('get_user', next)
})

export default router
