import axios from 'axios'
import api from './api'
import router from '@/router'

function generateNewStore() {
  return {
    shopName: '', shopDomain: '', accessToken: '',
  }
}

export const state = function () {
  return {
    stores: [],
    // steps: [
    //   { next: true },
    //   { next: true },
    //   { next: true },
    //   // { back: true }
    // ],
    currentStep: 0,
    store: generateNewStore(), // for testing: accessToken: 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
    // store: { shopName: '', shopDomain: '', accessToken: '' }, 
    showStores: true,
    webhook: false,
    isConnected: true,
  }
}

export const mutations = {
  SET_STATE(state, { key, value }) {
    state[key] = value
  },
  SET_CURRENT_STEP(state, value) {
    state.currentStep = value
  },
  SHOW_STORES(state, data) {
    state.showStores = data
  },
  SET_STORES(state, data) {
    state.stores = data
  },
  ADD_STORE(state, data) {
    state.stores.push(data)
  },
  DELETE_STORE(state, shopDomain) {
    const index = state.stores.findIndex(store => store.shopDomain === shopDomain)
    state.stores.splice(index, 1)
  },
  UPDATE_STORE(state, someStore) {
    const index = state.stores.findIndex(store => store.shopDomain === someStore.shopDomain)
    state.stores[index] = someStore
  },
  SET_STORE_CREDENTIALS(state, { name, value }) {
    state.store[name] = value
  },
  RESET_STORE(state) {
    state.store = generateNewStore()
  },
}

export const actions = {
  async get_stores({ state, commit }) {
    try {
      const { data } = await axios(api.stores)
      if (!data.length) {
        commit('SHOW_STORES', false)
        await router.push('/dashboard/add-store')
        return
      }
      commit('SET_STORES', data)
      commit('SHOW_STORES', true)
    } catch (e) { }
  },
  async delete_store({ state, commit }, shopDomain) {
    // debugger
    try {
      await axios.delete(`${api.stores}/${shopDomain}`)
      commit('DELETE_STORE', shopDomain)
    } catch (e) { }
  },
  async create_store({ state, commit }, store) {
    try {
      const { data } = await axios.post(api.stores, store)
      commit('ADD_STORE', { shopName: data.shopName, shopDomain: data.shopDomain, connected: data.connected, webhookActive: data.webhookActive })
    } catch (e) { }
  },
  async update_store({ state, commit }, someStore) {
    try {
      await axios.put(`${api.stores}/${someStore.shopDomain}`, { "connected": someStore.connected, "webhookActive": true })
      commit('UPDATE_STORE', someStore)
    } catch (e) { }
  },
  async verify_webhook({ state, commit }, shopDomain) {
    try {
      const { data } = await axios(`${api.stores}/${shopDomain}/verify-webhook`)
      // console.log('==before verify_webhook-->webhook.value is ', state.webhook)
      if (data.webhookActive) {
        commit('SET_STATE', { key: 'webhook', value: true })
        // console.log('==after verify_webhook-->webhook.value is ', state.webhook)
      } else {
        console.log('webhook returned false...')
      }
    } catch (e) { console.log(e) }
  }
}
