import { createStore } from 'vuex'
import * as auth from './modules/auth'
import * as dashboard from './modules/dashboard'

export default createStore({
  state: {
    isModal: false,
  },
  getters: {},
  mutations: {
    SET_MODAL (state, value) {
      state.isModal = value
    },
  },
  actions: {},
  modules: {
    auth,
    dashboard
  }
})
