import api from './api'
import axios from 'axios'
import router from '@/router'

export const state = () => ({
  user: {
    name: undefined
  },
  accountCreated: undefined,
  emailAlreadyExists: undefined,
  resendLink: false,
  validationTitle: 'Validating your account...',
  validationError: false,
  passResetLinkSent: false,
  accountNotFound: false,
  passwordChanged: false,
  accountNotActivated: false,
})

export const actions = {
  async get_user ({ commit }, next) {
    try {
      const { data: user } = await axios.get(api.login)
      if (user.name) {
        commit('SET_USER', user)
        next()
      }
    } catch (e) {
      next('/login')
    }
  },
  async login ({ commit }, user) {
    try {
      const { data, status } = await axios.post(api.login, user)
      if (status === 200) {
        commit('SET_USER', data)
        await router.push('/dashboard')
      }
    } catch (err) {
      commit('SET_STATE', { key: 'accountNotActivated', value: err.response.status === 405 })
    }
  },
  async logout ({ state, commit }) {
    try {
      await axios.delete(api.logout)
      commit('SET_USER', {})
      await router.push('/login')
    } catch (e) {}
  },
  async register ({ commit }, user) {
    try {
      const { data } = await axios.post(api.signup, user)
      commit('SET_STATE', { key: 'accountCreated', value: data })
      commit('SET_STATE', { key: 'emailAlreadyExists', value: false })
    } catch (err) {
      if (err.response?.status === 409) {
        commit('SET_STATE', { key: 'emailAlreadyExists', value: true })
      }
    }
  },
  async forgot_password ({ commit }, email) {
    try {
      await axios.post(api.forgotPassword, {email})
      commit('SET_STATE', { key: 'passResetLinkSent', value: true })
      commit('SET_STATE', { key: 'accountNotFound', value: false })
    } catch (err) {
      commit('SET_STATE', { key: 'accountNotFound', value: true })
    }
  },
  async resend_link ({ commit }, user) {
    try {
      await axios.get(`${api.resendRegisterLink}/${user.email}`)
      commit('SET_STATE', { key: 'resendLink', value: true })
      setTimeout(() => {
        commit('SET_STATE', { key: 'resendLink', value: false })
      }, 3000)
    } catch (err) {}
  },
  async validate_account ({ commit }, { id, email }) {
    try {
      const {
        data: { msg }
      } = await axios.post(`${api.verifySignup}/${id}/${email}`)
      if (msg === 'Unauthorized') {
        commit('SET_STATE', { key: 'validationError', value: true })
        commit('SET_STATE', {
          key: 'validationTitle',
          value: 'Account validation unsuccessfully.'
        })
        return
      }
      commit('SET_STATE', {
        key: 'validationTitle',
        value: 'Your account was successfully created!'
      })
    } catch (err) {
      // todo change to 404
      commit('SET_STATE', { key: 'validationError', value: true })
      commit('SET_STATE', {
        key: 'validationTitle',
        value: 'Account validation unsuccessfully.'
      })
    }
  },
  async reset_password ({commit}, {id, email, password}) {
    try {
      await axios.post(`${api.resetPassword}?id=${id}&email=${email}`, {password})
      commit('SET_STATE', { key: 'passwordChanged', value: true })
      setTimeout(async function () {
        await router.push('/login')
        commit('SET_STATE', { key: 'passwordChanged', value: false })
      }, 5000)
    } catch (e) {

    }
  },
  async delete_account ({commit}) {
    try {
      await axios.delete(api.account)
      await router.push('/login')
    } catch (e) {

    }
  },
}

export const mutations = {
  SET_STATE (state, { key, value }) {
    state[key] = value
  },
  SET_USER (state, user) {
    state.user = user
  }
}
